import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5C9 5.26522 9.10536 5.51957 9.29289 5.70711C9.48043 5.89464 9.73478 6 10 6H12C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4H10ZM7.87868 2.87868C8.44129 2.31607 9.20435 2 10 2H12C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.4407 3.19808 14.6807 3.5821 14.8284 4H16C16.7956 4 17.5587 4.31607 18.1213 4.87868C18.6839 5.44129 19 6.20435 19 7V10C19 10.5523 18.5523 11 18 11C17.4477 11 17 10.5523 17 10V7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H14.8284C14.6807 6.4179 14.4407 6.80192 14.1213 7.12132C13.5587 7.68393 12.7956 8 12 8H10C9.20435 8 8.44129 7.68393 7.87868 7.12132C7.55928 6.80192 7.31933 6.4179 7.17157 6H6C5.73478 6 5.48043 6.10536 5.29289 6.29289C5.10536 6.48043 5 6.73478 5 7V19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H6C5.20435 22 4.44129 21.6839 3.87868 21.1213C3.31607 20.5587 3 19.7957 3 19V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H7.17157C7.31933 3.5821 7.55928 3.19808 7.87868 2.87868ZM13.7071 10.2929C14.0976 10.6834 14.0976 11.3166 13.7071 11.7071L12.4142 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H12.4142L13.7071 16.2929C14.0976 16.6834 14.0976 17.3166 13.7071 17.7071C13.3166 18.0976 12.6834 18.0976 12.2929 17.7071L9.29289 14.7071C8.90237 14.3166 8.90237 13.6834 9.29289 13.2929L12.2929 10.2929C12.6834 9.90237 13.3166 9.90237 13.7071 10.2929Z",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}